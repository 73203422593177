<template>
  <div class="bills-content">
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <span class="h5 mb-0"><font-awesome-icon icon="money-check-alt" class="mt-1 mr-1 font-size-24" /> {{ $t('common.cryptoInvoices') }}</span>
      </div>
      <div class="d-flex mb-3">
        <div>
          <a-input-search
            v-model="searchValue"
            :placeholder="`# ${$t('common.txId')}`"
            style="width: 180px"
            @search="searchById"
            allowClear
          >
          </a-input-search>
        </div>
        <div class="ml-3">
          <agent-dropdown class="width-200" v-model="accountId" />
        </div>
        <div class="ml-3">
          {{ $t('common.status') }}:
          <a-select v-model="status" :placeholder="$t('placeholders.selectInvoiceStatus')" class="width-150">
            <a-select-option value="all" >{{ $t('pre.any') }}</a-select-option>
            <a-select-option value="unpaid" ><a-icon type="exclamation" class="text-danger" /> {{ $t('common.unpaid') }}</a-select-option>
            <a-select-option value="paid" ><a-icon type="check" class="text-success" /> {{ $t('common.paid') }}</a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-button @click="handleAgentChange"><a-icon type="check" /> {{ $t('buttons.btnApply') }}</a-button>
        </div>
        <div class="ml-auto">
<!--          <a-button type="primary"><a-icon type="file-excel" /> Экспорт</a-button>-->
          <a-button :loading="invoiceTableLoading" @click="refreshList"><a-icon type="reload" /> {{ $t('buttons.btnRefresh') }}</a-button>
        </div>
      </div>
      <a-table
        :columns="invoiceColumns"
        :dataSource="invoiceData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="invoiceTableLoading"
        @change="handleTableChange"
      >
        <span slot="status" slot-scope="status, record">
          <span v-if="invoiceLoading" class="ml-2"><a-icon type="loading" /></span>
          <a-tooltip v-if="status === 2" :title="`💰 ${$t('common.paid')}\n${$moment.parseZone(record.paid_at).format('DD.MM.YY HH:mm:ss')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
            <span class="font-size-18" >
              <font-awesome-icon icon="money-check" class="text-success" />
            </span>
          </a-tooltip>
          <span v-else>
            <span v-if="(status === 1 || status === 4) && !record.m_id">
              <a-tooltip :title="$t('tooltips.acceptInvoice')" placement="bottom">
                <a-popconfirm
                  :title="$t('popups.acceptInvoice')"
                  @confirm="acceptInvoice(record.id)"
                >
                  <span class="font-size-18 mr-2">
                    <a href="javascript:"><font-awesome-icon icon="check-circle" class="text-gray-6" /></a>
                  </span>
                </a-popconfirm>
              </a-tooltip>
            </span>
            <span v-if="record.is_master" class="mr-3">
              <a-tooltip :title="$t('common.invoiceMaster')" placement="bottom">
                <a-badge :count="record.children_count" :number-style="{
                  backgroundColor: '#fff',
                  color: '#999',
                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                  marginTop: '-6px'
                }">
<!--                  <a-avatar :size="22" class="text-white" :style="{backgroundColor: '#1890ff'}">M</a-avatar>-->
                  <font-awesome-icon icon="layer-group" class="text-primary font-size-18" />
                </a-badge>
              </a-tooltip>
              <span class="ml-3">
                <a-tooltip :title="`${$t('common.ungroup')}`" placement="bottom">
                  <a-popconfirm
                    :title="`${$t('common.ungroup')}`"
                    @confirm="deleteMaster(record.account.id)"
                  >
                    <a href="javascript:;"><font-awesome-icon icon="object-ungroup" class="mt-1 font-size-18 text-gray-5" /></a>
                  </a-popconfirm>
                </a-tooltip>
              </span>
            </span>
            <span v-else>
              <span v-if="!record.m_id">
<!--                <span v-if="status === 1">-->
<!--                  <a-tooltip  title="Принять платеж" placement="bottom">-->
<!--                    <a-popconfirm-->
<!--                      title="Средства могли не поступить в полном объеме. Принять платеж в любом случае?"-->
<!--                      @confirm="acceptInvoice(record.id)"-->
<!--                    >-->
<!--                      <span class="font-size-18 mr-2">-->
<!--                        <a href="javascript:"><font-awesome-icon icon="check-circle" class="text-gray-6" /></a>-->
<!--                      </span>-->
<!--                    </a-popconfirm>-->
<!--                  </a-tooltip>-->
<!--                </span>-->
                <span v-if="(status === 0 && record.tmp) || (status === 4)">
                  <a-tooltip :title="$t('tooltips.invoiceWriteOffDebt')" placement="bottom">
                    <a-popconfirm
                      :title="`${$t('common.sure')}${$t('pre.to')}${$t('buttons.btnDelete')}?`"
                      @confirm="deleteInvoice(record.id)"
                    >
                      <span class="font-size-18 mr-2">
                        <a href="javascript:"><font-awesome-icon icon="minus-circle" class="text-danger" /></a>
                      </span>
                    </a-popconfirm>
                  </a-tooltip>
                </span>
              </span>
            </span>
            <span v-if="status === 0 && !record.tmp">
              <a-tooltip v-if="record.err_cnt > 0" :title="`❗${$t('tooltips.invoiceGetAddressError')}`" placement="bottom">
                <span class="font-size-18">
                  ❗<font-awesome-icon icon="money-check" class="text-gray-4" />
                </span>
              </a-tooltip>
              <a-tooltip v-else :title="$t('tooltips.invoiceWaitingAddress')" placement="bottom">
                <span class="font-size-18">
                  <font-awesome-icon icon="money-check" class="text-gray-4" />
                </span>
              </a-tooltip>
            </span>
            <a-tooltip v-if="status === 0 && record.tmp && record.to_del" :title="`⏱ ${$t('common.temporary')}\n${$t('tooltips.invoiceWillDeleteOnIdle')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18 pr-1">
                <font-awesome-icon icon="clock" class="text-gray-4" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 0 && record.tmp && !record.to_del" :title="`👮 ${$t('common.temporary')}\n ${$t('pre.at')}${$t('common.arbitration')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 1" :title="$t('statuses.payment.pending')" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-warning" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 3" :title="$t('statuses.canceled')" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-danger" />
              </span>
            </a-tooltip>
            <a-tooltip v-if="status === 4" :title="`${$t('statuses.expired')}\n${$moment.parseZone(record.expires_at).format('DD.MM.YY HH:mm:ss')}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-danger" />
              </span>
            </a-tooltip>
          </span>
        </span>
        <template v-slot:created_at="created_at">
          {{ $moment.parseZone(created_at).format('DD.MM.YY') }}
          <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment.parseZone(created_at).format('HH:mm') }}</span>
        </template>
        <template v-slot:tx_id="tx_id, record">
          <text-clipboard
            v-if="tx_id"
            :text="tx_id"
            :class="{ 'font-weight-bold': record.is_master }"
          />
          <span v-if="record.m_id" class="d-block mt-1">
            <a-tooltip :title="`${$t('common.linked')}${$t('common.invoiceMaster')}`" placement="bottom">
              <span class="font-size-12 text-gray-5 ml-1"><font-awesome-icon icon="layer-group" class="" /> M{{ record.m_id }}</span>
            </a-tooltip>
          </span>
        </template>
        <template v-slot:amount="amount, record">
          <span v-if="amount">
            {{ amount }} <span v-if="record.currency.abbr">{{ record.currency.abbr }}</span>
          </span>
        </template>
        <template v-slot:amount_crypto="amount_crypto, record">
          <span v-if="amount_crypto">
            {{ Number(amount_crypto).toFixed(4) }}
            <span v-if="record.currency_crypto" class="font-size-12 text-gray-5">
              {{ record.currency_crypto.name }}
            </span>
          </span>
        </template>
        <template v-slot:payment="payment, record">
          <span v-if="record.is_master">
            {{ record.p_ids.join(', ') }}
          </span>
          <span v-else>
            <span v-if="payment">
              <a-tag>{{ payment.id }}</a-tag>
            </span>
          </span>

        </template>
        <template v-slot:account="account, record">
          <span v-if="account">
            <span class="mr-1">
              <img v-if="account.sex === 'male'" src="/resources/images/avatars/user-agent-m.png" width="18"/>
              <img v-else src="/resources/images/avatars/user-agent-w.png" width="18"/>
            </span>
            {{ record.account.name }}
            <a-tooltip v-if="record.account.is_coward" title="Трусливый" placement="bottom">
              <span class="float-right">🐤</span>
            </a-tooltip>
          </span>
        </template>
        <template v-slot:client="client">
            <span v-if="client">{{ client }}</span>
        </template>
        <span slot="address" slot-scope="address, record">
          <text-clipboard v-if="address" :text="address" />
          <span v-if="record.address_legacy" class="d-block">
            <a-badge color="orange" text="" />
            ...{{ record.address_legacy.slice(-8) }}
            <a-tooltip :title="$t('tooltips.copyToClipboard')" placement="right">
              <a href="javascript:;"><a-icon type="copy" class="text-primary" @click="clipboardHandle(record.address_legacy)" /></a>
            </a-tooltip>
          </span>
          <span v-if="!address && !record.address_legacy">
            <a-tooltip :title="`${$t('pre.no')} ${$t('common.addresses')}`" placement="bottom">
              <a-icon  type="warning"  class="text-warning" />
            </a-tooltip>
          </span>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment-timezone'
import AgentDropdown from '../../components/agent/AgentDropdown.vue'
import TextClipboard from '../../components/table/atomic/TextClipboard.vue'

const invoiceColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Billed',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: { customRender: 'created_at' },
  },
  {
    title: 'TX_ID',
    dataIndex: 'tx_id',
    key: 'tx_id',
    width: '12%',
    scopedSlots: { customRender: 'tx_id' },
  },
  {
    title: 'Agent',
    dataIndex: 'account',
    key: 'account',
    width: '12%',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Order',
    dataIndex: 'payment',
    key: 'payment',
    width: '8%',
    scopedSlots: { customRender: 'payment' },
  },
  {
    title: 'Client',
    dataIndex: 'payment.client_id',
    key: 'payment.client_id',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'USD',
    dataIndex: 'amount',
    key: 'amount',
    width: '10%',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Crypto',
    dataIndex: 'amount_crypto',
    key: 'amount_crypto',
    width: '12%',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount_crypto' },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    // sorter: (a, b) => a.income - b.income,
    width: '15%',
    scopedSlots: { customRender: 'address' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '20%',
    align: 'right',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'index',
  components: { TextClipboard, AgentDropdown },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      invoiceData: [],
      accountsData: [],
      invoiceColumns,
      moment,
      invoiceLoading: false,
      invoiceTableLoading: false,
      pagination: { pageSize: 20 },
      searchField: 'tx_id',
      searchValue: '',
      accountId: 0,
      status: 'all',
    }
  },
  mounted () {
    this.getAccounts().then(() => { this.fetch() })
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
        account_id: this.accountId,
        status: this.status,
      })
    },
    handleAgentChange() {
      console.log('agent changed')
      this.searchValue = ''
      this.pagination.current = 1
      this.refreshList()
    },
    async fetch(params = {}) {
      console.log('params:', params)
      this.invoiceTableLoading = true
      const reqData = { results: 20, ...params }
      console.log(reqData)
      const url = '/admin/invoices'
      return await apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.invoiceTableLoading = false
        this.invoiceData = response.data.data
        this.pagination = pagination
        console.log(this.invoiceData)
      }).catch(error => {
        console.log(error)
        this.invoiceTableLoading = false
      })
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        account_id: this.accountId,
        status: this.status,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    async searchById(value) {
      this.accountId = 0
      this.status = 'all'
      this.searchField = 'tx_id'
      this.pagination.current = 1
      this.refreshList()
    },
    async acceptInvoice(invoiceId) {
      const url = `/admin/invoices/${invoiceId}/accept`
      return await apiClient.patch(url).then((response) => {
        // console.log(response.data.data)
        this.$notification.success({
          message: `${this.$t('common.invoice')} ${this.$t('statuses.accepted')}`,
          description: `Счет #${response.data.data.tx_id}`,
        })
        this.refreshList()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('messages.errorAcceptInvoice'),
          description: error.message,
        })
      })
    },
    async deleteInvoice(invoiceId) {
      const url = `/admin/invoices/${invoiceId}`
      return await apiClient.delete(url).then((response) => {
        console.log(response.data.data)
        const index = this.invoiceData.findIndex(obj => obj.id === invoiceId)
        this.invoiceData.splice(index, 1)
        this.$notification.success({
          message: `${this.$t('common.invoiceMaster')} ${this.$t('statuses.deleted')}`,
          description: '',
        })
        // this.refreshList()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('messages.errorDeletingInvoice'),
          description: error.message,
        })
      })
    },
    async getAccounts() {
      const url = '/admin/accounts?short'
      return apiClient.get(url).then((response) => {
        this.accountsData = response.data.data
        console.log('accounts', this.accountsData)
      }).catch(error => { console.log(error) })
    },
    async deleteMaster(accountId) {
      const url = '/admin/invoices/master/' + accountId
      return apiClient.delete(url).then((response) => {
        this.refreshList()
        console.log('master invoice deleted for account ' + accountId)
      }).catch(error => { console.log(error) })
    },
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: `${v.slice(0, 16)}..`,
      })
    },
  },
}
</script>

<style scoped>

</style>
